import React from "react"
import ReactPlayer from "react-player"
import { Container, Icon } from "semantic-ui-react"
import styles from "./VideoModal.module.scss"
import Vimeo from "@u-wave/react-vimeo"

function VideoModal(props) {
  function renderDetails() {
    return props.selectedWork.details.map((detail, i) => {
      return (
        <div key={i} className={styles.detail}>
          <span>{detail.value}</span>
          <h2 className={styles.title}>{detail.name}</h2>
        </div>
      )
    })
  }

  return (
    <>
      {props.selectedWork && (
        <div className={styles.modalContainer}>
          <Container>
            <div className={styles.closedButtonContainer}>
              <div
                className={styles.closedButton}
                onClick={() => props.setSelectedWork(null)}
              >
                Close <Icon className={styles.closeIcon} name="close" />
              </div>
            </div>
            <div className={styles.videoOuterContainer}>
              <div className={styles.videoContainer}>
                {/* <ReactPlayer
                className={styles.video}
                url={`https://player.vimeo.com/video/${props.selectedWork.videoId}`}
              /> */}
                {/* <Vimeo
                className={styles.video}
                video={props.selectedWork.videoId}
              /> */}
                <div className="vimeo-video-container">
                  <iframe
                    className="vimeo-video"
                    src={`https://player.vimeo.com/video/${props.selectedWork.videoId}`}
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              {props.selectedWork.details && (
                <div className={styles.detailsContainer}>{renderDetails()}</div>
              )}
            </div>
          </Container>
        </div>
      )}
    </>
  )
}

export default VideoModal
